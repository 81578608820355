.po_items_ho {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));
    gap: 2rem;
}

.po_item {
    min-width: 100px;
    min-height: 100px;
    max-width: 300px;
    max-height: 300px;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    padding: 1px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
    margin-top: 20px;
}

.po_item img {
    max-width: 100%;
    max-height: 300px;
    min-height: 100px;
    filter: none;
}

.po_item:hover img {
    filter: blur(3px);
    transition: filter 0.3s ease;
}

.po_item .content {
    position: absolute;
    height: 20%;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    border: 1px solid black;
}

.po_item .content {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: 60%;
    width: 100%;
    opacity: 1;
}

.po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .content a:hover {
    text-decoration: none;
}