.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
  padding: 0 10px;
}

.images div {
  overflow: hidden;
}

.images img {
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  transition: filter 0.5s ease;
  transform: scale(1);
  transition: 0.3s ease-in-out;
  transform-origin: center;
}

.images img:hover {
  filter: none;
  transform: scale(1.1);
}